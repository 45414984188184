<template>
  <b10-base>
    <b10-date-picker
      v-model="form.fparte"
      title="Fecha"
      :rules="formRules.fparte"
    />
    <b10-autocomplete
      v-show="isInsert"
      v-model="form.idpuestoServicio"
      label="Puesto de servicio"
      :items="puestosServicio"
      item-text="descripcion"
      item-value="idpuesto_servicio"
      :rules="formRules.idpuestoServicio"
      :disabled="!!presenciaIdpuestoServicio"
      clearable
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteNovedadFormData'
import { get } from 'vuex-pathify'
import { currentDateTime } from '@/utils/date'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      puestosServicio: [],
      form: {
        fparte: currentDateTime(),
        idpuestoServicio: null,
        observaciones: '',
        idtparte_novedad: null,
      },
      formRules: {
        idpuestoServicio: [
          v => !this.isInsert || !!v || 'Campo requerido'
        ],
        fparte: [
          v => !!v || 'Campo requerido'
        ],
      },
    }
  },
  computed: {
    usuarioIdvigilante: get('usuario/idvigilante'),
    presenciaIdpuestoServicio: get('presencia/idpuestoServicio'),
  },
  async created () {
    const [dataset] = await this.$online.puestoServicio.selectPorVigilante(this.usuarioIdvigilante)
    this.puestosServicio = dataset
    const puestoServicio = _.find(dataset, {'idpuesto_servicio': this.presenciaIdpuestoServicio})
    this.$set(this.form, 'idpuestoServicio', this.presenciaIdpuestoServicio)
    this.$set(this.form, 'idtparte_novedad', puestoServicio.idtparte_novedad)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'fparte', formData.fparte)
      this.$set(this.form, 'observaciones', formData.observaciones)
    },
  },
}
</script>
