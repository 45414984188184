export default {
  async insert (Vue, formData, idempresa) {
    const resp = await Vue.$api.call(
      'parteNovedad.insert',
      {
        values: {
          idpuesto_servicio: formData.idpuestoServicio,
          fparte: formData.fparte,
          observaciones: formData.observaciones,
          idtparte_novedad: formData.idtparte_novedad,
          idempresa: idempresa,
        }
      }
    )
    return resp.data.result.dataset[0]
  },
}
